import { LeadFormType } from '@/app/_components/modals/enquire-forms/enquiry-forms/enquiry-forms';
import { NEW_CARS_LIST_FIELDS } from '@/constants/new-cars/new-cars.constants';
import {
  duoporta_record_ID_Name,
  duoportaId,
  introDateId,
  priceName,
  titleName,
} from '@/constants/specials/mm_code.constants';
import {
  NEW_KIA_SPECIALS_ID,
  SEO_SPECIAL,
  VEHICLE_TYPE_NEW,
} from '@/constants/specials/specials.constants';
import { KIA_RETAIL_GROUP, KIA_SPECIAL } from '@/constants/vars.constants';
import { apiService, directusService, limiter } from '@/services/api.service';
import { getImageLink } from '@/services/link.service';
import { mapDuoportaData } from '@/services/NewCarsService.service';
import { DuoportaMMCodeDTO } from '@/types/duoporta/duoporta.types';
import {
  DuoportaFeature,
  DuoportaFeatureDTO,
  DuoportaSpecification,
  DuoportaSpecificationDTO,
  SpecialCarType,
} from '@/types/specials/specials.types';
import {
  BrowseItemTypes,
  SpecialFor,
  VehicleType,
} from '@/types/used-cars/used-cars.types';

const mapDuoportaSpecificationData = (
  data: DuoportaSpecificationDTO,
): DuoportaSpecification => {
  return {
    id: data.id,
    name: data.name,
    value: data.value,
  };
};

const mapDuoportaFeatures = (data: DuoportaFeatureDTO): DuoportaFeature => {
  return {
    id: data.id,
    name: data.name,
    value: data.value,
  };
};

export const getSpecificationsByDuoportaId = async (
  id: string,
): Promise<DuoportaSpecification[]> => {
  try {
    const data: DuoportaSpecificationDTO[] = await apiService.get(
      `duoporta/specifications/${id}`,
    );

    return data.map((specification) =>
      mapDuoportaSpecificationData(specification),
    );
  } catch (e) {
    console.error(e)
    return []
  }
};

export const getLeadTypeForSpecial = (special: {
  special_for: SpecialFor;
  vehicle_type: VehicleType;
}): LeadFormType => {
  if (special.special_for === SpecialFor.Parts) {
    return 'parts';
  }

  if (special.special_for === SpecialFor.Service) {
    return 'service';
  }

  if (special.vehicle_type === VehicleType.New) {
    return 'new-cars';
  } else {
    return 'used-cars';
  }
};

export const getFeaturesByDuoportaId = async (
  id: string,
): Promise<DuoportaFeature[]> => {
  const data: DuoportaFeatureDTO[] = await apiService.get(
    `duoporta/features/${id}`,
  );

  return data.map((feature) => mapDuoportaFeatures(feature));
};

export const mapNewCarItemResponse = (item, specificationData) => ({
  discDataSpec:
    specificationData?.length > 0
      ? specificationData?.map((data) =>
          mapDuoportaData(data, item?.vehicle_preview || null),
        )
      : null,
});

export const getCMSVehicle = limiter.wrap(async (mmcode: string) => {
  const { data }: any = await directusService.get('items/cms_new_cars');
  const matchedCMSObject = data.find((item) => {
    return item.specification_prices
      ? item.specification_prices?.some((spec) => spec.mm_code === mmcode)
      : item;
  });

  if (!matchedCMSObject) {
    return null;
  } else {
    return matchedCMSObject;
  }
});

export const getCMSVehicleByMMCode = async (mmcode: string) => {
  const { data } = await directusService.get('items/cms_new_cars', {
    params: {
      fields: NEW_CARS_LIST_FIELDS,
    },
  });
  const matchedCMSObject = data.find((item) => {
    return item.specification_prices
      ? item.specification_prices?.some((spec) => spec.mm_code === mmcode)
      : item;
  });

  if (!matchedCMSObject) {
    return null;
  } else {
    return matchedCMSObject;
  }
};

export const getCMSVehicleByModel = async (model: string) => {
  const matchedCMSObject = await directusService.get('items/cms_new_cars', {
    params: {
      fields: 'features_columns.*.*',
      filter: { main_title: { _eq: `${model}` } },
    },
  });

  if (!matchedCMSObject) {
    return null;
  } else {
    return matchedCMSObject.data.map((features) => {
      return features.features_columns;
    });
  }
};

export const getDataByMultipleMMCodes = async (mmcodes: string[]) => {
  const duoportaMap = mmcodes.map(async (mmcode) => {
    return await getDataByMMCode(mmcode);
  });

  return await Promise.allSettled(duoportaMap);
};

export const getDataByMMCode = async (mmcode: string) => {
  let data: DuoportaMMCodeDTO[];

  data = await apiService.get(`duoporta/mmlookup/${mmcode}`);

  const duoportaID = data.map((data1) => {
    const duoportaModelId = data1?.fields?.find(
      (item) => item.id === duoportaId,
    )?.value;

    return duoportaModelId;
  });

  const matchedCMSObject = await getCMSVehicle(mmcode);

  const specifications = await Promise.all(
    duoportaID?.map(async (id) => {
      const specificationData = await getSpecificationsByDuoportaId(id);
      const featureData = await getFeaturesByDuoportaId(id);

      return [...specificationData, ...featureData];
    }),
  );

  const specificationData = specifications?.map((specification, index) => {
    const price = specification.find((price) => {
      return price.name === priceName;
    });
    const title = specification.find((title) => {
      return title.name === titleName;
    });
    const year = specification
      .find((item) => item.id === introDateId)
      ?.value.slice(0, 4);
    const duoporta_record_ID = specification.find((duoporta_record_ID) => {
      return duoporta_record_ID.name === duoporta_record_ID_Name;
    });

    return {
      title: title.value,
      price: price.value,
      mm_code: mmcode,
      duoporta_model_id: duoporta_record_ID.value,
      year: year,
      specification_data: specifications[index],
    };
  });

  specificationData.sort((a, b) => {
    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    return 0;
  });

  const itemData = mapNewCarItemResponse(matchedCMSObject, specificationData);

  return itemData;
};

export const mapSpecialsHeaderResponse = (header) => ({
  headerDescription: header.header_description,
  headerImage: getImageLink(header.header_image),
});

export const mapKiaModelsResponse = (brand) => {
  return brand.range_ids
    .sort((a, b) => a.sort - b.sort)
    .map((model) => ({
      name: model.name || null,
      image: model.logo || null,
      specials: model.specials || [],
      id: model.id || null,
    }));
};

export const mapRangesResponse = (ranges) => {
  return ranges.map((range) => ({
    name: range.name || null,
    image: range.logo || null,
    specials: range.specials || [],
    id: range.id || null,
  }));
};

export const mapSpecialsResponse = (specials) => {
  return specials?.map((special) => {
    if (special.special_type === 'discount' && !special.vehicle_type) {
      special.vehicle_type = '1';
    }

    return {
      id: special.id,
      title: special.title,
      shortDescription: special.short_description || '',
      image: special.image,
      subTitle: special.sub_title,
      specialСategory: special.special_category || '',
      description: special.description,
      monthlyRepayment: Math.ceil(special.monthly_repayment),
      discountValue: special.discount_value,
      specialType: special.special_type,
      specialFor: special.special_for,
      vehicle_type: special.vehicle_type,
      brand: special.brand,
      range: special.range,
    };
  });
};

export const mapResponseAllActiveSpecials = (specials) => {
  const arr = [];

  specials.map((el) => {
    return arr.push({
      id: el?.special_category?.id ?? null,
      title: el?.special_category?.title ?? null,
      description: el?.special_category?.description ?? null,
      value: el?.special_category?.value ?? null,
      specials: [],
    });
  });

  const unique = [...new Map(arr.map((item) => [item.id, item])).values()];

  unique.map((el) => {
    const specialsArr = mapSpecialsResponse(
      specials.filter((spec) => spec?.special_category?.id === el?.id),
    );

    return (el.specials = [...specialsArr]);
  });

  return unique;
};

export const getSpecialsByCategories = (specials, specialСategory) => {
  return specials.filter((el) => el.specialСategory?.id === specialСategory);
};

export const getSpecialsByCarType = (specials, carType: SpecialCarType) => {
  return specials.filter((el) => {
    if (carType === 'new-cars') {
      return (
        el.vehicle_type === VEHICLE_TYPE_NEW &&
        el.specialСategory.id === NEW_KIA_SPECIALS_ID
      );
    } else {
      return el.vehicle_type !== VEHICLE_TYPE_NEW;
    }
  });
};

export const mapCategoryItem = (data): BrowseItemTypes[] => {
  return data?.map((item) => ({
    shortDescription: item.short_description,
    id: item.id,
    fullName: item.fullName,
    name: item.name,
    isDisabled: item?.isDisabled,
    specials: item.specials || [],
    hasApprovedSpecials: item?.hasApprovedSpecials,
    hasActiveSpecialDates: item?.hasActiveSpecialDates,
    image: item.image || item.logo,
    link: `/specials/${item.name.toLowerCase()}`,
  }));
};

export const mapSpecialsCategoriesPaths = (specialsCategories) => {
  const paths = [];

  specialsCategories.map(({ value }) => {
    if (value != null) {
      paths.push({ params: { category: value } });
    }
  });

  return paths;
};

export const variableForTitleSpecials = (filter, query) => {
  return Object.keys(filter).length === 0
    ? 'all'
    : query.range && Object.keys(filter).length !== 0
      ? 'range'
      : (query.vehicle_type === '1' || query.vehicle_type === '2') &&
          query.special_type === 'offer' &&
          Object.keys(filter).length !== 0
        ? 'used_cars_offer'
        : query.vehicle_type === '3' &&
            query.special_type === 'offer' &&
            Object.keys(filter).length !== 0
          ? 'demo_cars_offer'
          : query.special_type === 'discount' &&
              Object.keys(filter).length !== 0
            ? 'discount'
            : query.special_type === 'offer' && Object.keys(filter).length !== 0
              ? 'offers'
              : '';
};

export const getSeoTitleForPriceCrush = (data) => {
  const title = `${data.title} | ${KIA_SPECIAL} | ${SEO_SPECIAL.CAR_SPECIAL} ${KIA_RETAIL_GROUP}`;

  return title;
};
