export const brandRangeModelId = 6;
export const introDateId = 40;
export const duoportaId = 101;
export const brandRangeModelIdForSpecial = 49;
export const priceName = 'price';
export const titleName = 'brand range model';
export const duoporta_record_ID_Name = 'duoporta record ID';
export const specialBrandKiaID = 52;
export const specialNewCars = '4';
export const specialDemoCars = '3';
export const specialUsedCars = '1';
